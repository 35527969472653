import { gql } from '@apollo/client';
import { SPOT_GUEST_MEMBERSHIPS, SPOT_LISTINGS_INFO } from './spot-fragments';
import { SHORT_RESERVATION_FRAGMENT } from './reservation-fragments';
import { USER_EXTRAS_INFO } from './extra-fragments';

export const USER_CREDITS_INFO = gql`
    fragment UserCredits on User {
        credits {
            available
            inviteTotal
            membershipped
            sniffpassed
            total
        }
    }
`;

export const USER_LOCATION_INFO = gql`
    fragment UserLocationInfo on User {
        latitude
        longitude
        state
        city
    }
`;

export const USER_BASE_INFO = gql`
    fragment UserBaseInfo on User {
        about
        id
        firstname
        lastInitial
        rating
        avatar
        avatarAws
    }
`;

export const USER_SHORT_INFO = gql`
    fragment UserShortInfo on User {
        ...UserBaseInfo
        ...UserLocationInfo
    }
    ${USER_BASE_INFO}
    ${USER_LOCATION_INFO}
`;

export const USER_PAYOUT_INFO = gql`
    fragment UserPayoutInfo on User {
        payoutEnabled
        payoutAccountId
        payoutAccounts {
            id
            bankName
            currency
            last4
        }
        payouts(page: 1, limit: 1) {
            metadata {
                totalCount
                totalEarnings
                monthToDateEarnings
            }
        }
    }
`;

export const USER_LONG_INFO = gql`
    fragment UserLongInfo on User {
        ...UserShortInfo
        ...UserExtras
        ...UserPayoutInfo
        phoneNumber
        phoneNumberVerified
        hasUpcomingReservations
        aboutHost
        aboutHostMore
        email
        emailVerified
        unconfirmedEmail
        lastname
        firstHear
        tosAgree
        vaccinated
        hostRulesAccepted
        rulesAccepted
        totalEarnings
        referralCode
        gender
        birthAt
        race
        newReviewsCount
        instagramUsername
        inMembershipProgram
        reviewsCount
        lastDogsCount
        reservationsCount
        hostMode
        changeRate
        testGroups
        memberships {
            id
            active
            endsAt
            remainingCredits
            sniffpassType
            price
            spot {
                id
            }
            status
        }
        hasCanceledSniffpassTrial
        hasSniffpassTrial
        bannerStatuses
        hostWelcomeMessageBanner
        hostWelcomeMessageText
        hostWelcomeMessagesOn
        hostThankYouMessageText
        hostThankYouMessagesOn
        cards {
            id
            brand
            last4
            expMonth
            expYear
            name
            default
        }
        stripeVerified
        tester
    }
    ${USER_EXTRAS_INFO}
    ${USER_SHORT_INFO}
    ${USER_PAYOUT_INFO}
`;

export const USER_HOMEPAGE_INFO = gql`
    fragment UserHomeInfo on User {
        reservationsForReview {
            ...ShortReservation
            firstReview
        }
        visitAgainSpots {
            ...SpotListingsInfo
            isMember
        }
        otherSpotsYouMayLike {
            ...SpotListingsInfo
        }
    }
    ${SPOT_LISTINGS_INFO}
    ${SHORT_RESERVATION_FRAGMENT}
`;

export const SPOT_ALERT_INFO = gql`
    fragment SpotAlertInfo on SpotAlert {
        id
        radius
        enclosureType
        minimumSize
        dogsAllowed
        latitude
        longitude
        active
    }
`;

export const USER_MEMBERSHIP_SUBS = gql`
    fragment UserMembershipSubs on User {
        memberships {
            id
            dogs
            hours
            price
            remainingHours
            remainingCredits
            active
            endsAt
            status
            sniffpassType
            period
            spot {
                ...SpotGuestMemberships
            }
        }
    }
    ${SPOT_GUEST_MEMBERSHIPS}
`;

export const USER_SPOT_ALERT_FRAGMENT = gql`
    fragment UserSpotAlert on User {
        spotAlert {
            ...SpotAlertInfo
        }
    }
    ${SPOT_ALERT_INFO}
`;
